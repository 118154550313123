import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const ASSET_NAME = 'landing-page';


export const getFeaturedListings = (params) => (dispatch, getState, sdk) => {
  return sdk.listings.query({
    include: ['author', 'author.profileImage', 'images'],
    sort: 'createdAt',
    pub_isFeatured: true
  }).then(res => {
    const listings = res.data.data;
    const included = res.data.included;
    const newListings = listings.map(listing => {
      const imageId = listing.relationships.images?.data[0]?.id?.uuid;
      const image = included.find(item => item.id.uuid === imageId);
      return { ...listing, image }
    })
    return newListings.slice(0, 20);
  }
  ).catch(e => console.log(e))
};

export const loadData = (params, search) => dispatch => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  return dispatch(fetchPageAssets(pageAsset, true));
};
