import React from 'react';
import css from './SearchBar.module.css';
import { Button, Menu, MenuContent, MenuItem, MenuLabel, NamedLink } from '../../../../components';
import { listingFields } from '../../../../config/configListing';

const SearchBar = () => {
  const allCategories = listingFields.find(i => i.key === 'category1')?.enumOptions || [];

  const allBrands1 = listingFields.find(i => i.key === 'brand')?.enumOptions || [];
  const allBrands = allBrands1.slice(0, 20);

  const links = [   
    {
      dropdown: false,
      component: (
        <Menu>
        {/* GENERAL CLEANING AND OTHER HOUSE SERVICES */}
        <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
          <span>GENERAL CLEANING</span>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          {/* INDOOR CLEANING */}
          <MenuItem key={'indoorCleaning'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=indoor-cleaning` }}>Indoor cleaning</NamedLink>
            </div>
          </MenuItem>

          {/* OUTDOOR CLEANING */}
          <MenuItem key={'outdoorCleaning'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=outdoor-cleaning` }}>Outdoor cleaning</NamedLink>
            </div>
          </MenuItem>

          {/* COOKING */}
          <MenuItem key={'cooking'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=cooking` }}>Cooking</NamedLink>
            </div>
          </MenuItem>

          {/* LAUNDRY */}
          <MenuItem key={'laundry'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=laundry` }}>Laundry</NamedLink>
            </div>
          </MenuItem>

          {/* OTHER SERVICES */}
          <MenuItem key={'otherServices'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=other-services` }}>Other services</NamedLink>
            </div>
          </MenuItem>
        </MenuContent>
      </Menu>
      ),
      search: '?pub_category=general_cleaning',
      label: 'General Cleaning',
    },
    
    {
      dropdown: true,
      component: (
      <Menu>
        {/* INDOOR CLEANING */}
        <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
          <span>Indoor Cleaning</span>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key={'washingDishes'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=washing-dishes` }}>Washing dishes</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'fridge'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=fridge` }}>Fridge</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'toiletAndBathroom'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=toilet-and-bathroom` }}>Toilet and bathroom</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'oven'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=oven` }}>Oven</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'cupboards'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=cupboards` }}>Cupboards</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'booksAndBookShelves'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=books-and-book-shelves` }}>Books and book shelves</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'carpetCleaning'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=carpet-cleaning` }}>Carpet cleaning</NamedLink>
            </div>
          </MenuItem>
        </MenuContent>
        </Menu>
      )
    },
    {
      dropdown: true,
      component: (
      <Menu>
      
        {/* OUTDOOR CLEANING */}
        <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
          <span>Outdoor Cleaning</span>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key={'balconyStairsVeranda'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=balcony-stairs-veranda` }}>Balcony, stairs and veranda</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'gardenSweeping'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=garden-sweeping` }}>Garden sweeping</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'gardening'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=gardening` }}>Gardening</NamedLink>
            </div>
          </MenuItem>
        </MenuContent>
        </Menu>
      )
    },
    {
      dropdown: true,
      component: (
      <Menu>
      
        {/* COOKING */}
        <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
          <span>Cooking</span>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key={'generalCooking'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=general-cooking` }}>General cooking</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'chapati'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=chapati` }}>Chapati</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'maandazi'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=maandazi` }}>Maandazi</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'swahiliDishes'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=swahili-dishes` }}>Swahili dishes (pilau etc)</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'localDishes'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=local-dishes` }}>Local dishes</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'westernDishes'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=western-dishes` }}>Western dishes</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'indianDishes'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=indian-dishes` }}>Indian dishes</NamedLink>
            </div>
          </MenuItem>
        </MenuContent>
        </Menu>
      )
    },
    {
      dropdown: true,
      component: (
      <Menu>

      
        {/* LAUNDRY */}
        <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
          <span>Laundry</span>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key={'washingClothes'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=washing-clothes` }}>Washing clothes</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'washingDuvetsBlanket'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=washing-duvets-blanket` }}>Washing duvets/blanket</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'ironing'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=ironing` }}>Ironing</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'folding'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=folding` }}>Folding</NamedLink>
            </div>
          </MenuItem>
        </MenuContent>
        </Menu>
      )
    },
    {
      dropdown: true,
      component: (
      <Menu>
      
        {/* OTHER SERVICES */}
        <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
          <span>Other Services</span>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key={'pickingKids'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=picking-kids` }}>Picking kids from school or bus stop</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'babySitting'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=baby-sitting` }}>Baby-sitting</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'takingKidsOut'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=taking-kids-out` }}>Taking kids out (e.g. park or mall)</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'carWashing'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=car-washing` }}>Car washing</NamedLink>
            </div>
          </MenuItem>
          <MenuItem key={'groceryShopping'}>
            <div className={css.singleMenuWrapper}>
              <NamedLink className={css.singleMenuOption} name="SearchPage" to={{ search: `?pub_category=grocery-shopping` }}>Grocery shopping</NamedLink>
            </div>
          </MenuItem>
        </MenuContent>
      
      </Menu>      
      ),
    },
    
    

    // {
    //   dropdown: true,
    //   component: (
    //     <Menu>
    //       <MenuLabel className={css.link} isOpenClassName={css.profileMenuIsOpen}>
    //         <span>Brands</span>
    //       </MenuLabel>
    //       <MenuContent className={css.profileMenuContent}>
    //         <MenuItem key={'singleMenu'}>
    //           <div className={css.doubleMenuWrapper}>
    //             {allBrands.map(c => {
    //               return (
    //                 <NamedLink
    //                   className={css.menuOption}
    //                   name="SearchPage"
    //                   to={{
    //                     search: `?pub_brand=${c.option}`,
    //                   }}
    //                 >
    //                   {c.label}
    //                 </NamedLink>
    //               );
    //             })}
    //           </div>
    //         </MenuItem>
    //       </MenuContent>
    //     </Menu>
    //   ),
    // },

    // {
    //   label: 'Stores',
    //   search: '?pub_userType=store',
    // },

    // {
    //   label: 'Artist shop',
    //   search: '?pub_userType=artist',
    // },
  ];
  return (
    <div className={css.wrapper}>
      <div className={css.wrapperLeft}>
        {links.map(l => {
          if (l.dropdown) {
            return l.component;
          } else 
          {
            return (
              <NamedLink
                className={css.link}
                name="SearchPage"
                to={{
                  search: l.search,
                }}
              >
                {l.label}
              </NamedLink>
            );
          }
          
        })}
      </div>
      {/* <div className={css.wrapperRight}>
        <NamedLink name="NewListingPage" className={css.newListingLink}>
          Sell
        </NamedLink>
      </div> */}
    </div>
  );
};

export default SearchBar;
