import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
//
// NOTE: these are highly recommended, since they
//       1) help customers to find relevant locations, and
//       2) reduce the cost of using map providers geocoding API
const defaultLocations = [
  {
    id: "default-nairobi",
    predictionPlace: {
      address: "Nairobi, Kenya",
      bounds: new LatLngBounds(new LatLng(-1.164743987404677, 37.04937464111994), new LatLng(-1.441673850747034, 36.64541913416444)),
    }
  },
  {
    id: "default-mombasa",
    predictionPlace: {
      address: "Mombasa, Kenya",
      "bounds": new LatLngBounds(new LatLng(-3.951664503200813, 39.76337726641146), new LatLng(-4.118712968027525, 39.56914901813537))
    }
  },
  {
    id: "default-nakuru",
    predictionPlace: {
      address: "Nakuru, Kenya",
      bounds: new LatLngBounds(new LatLng(-0.2083105525442332, 36.19723723446084), new LatLng(-0.4233130737472416, 35.96048355500845))
    }
  },
  {
    id: "default-kisumu",
    predictionPlace: {
      address: "Kisumu, Kenya",
      bounds: new LatLngBounds(new LatLng(0.03750805225548338, 34.90124694811951), new LatLng(-0.1874538492455863, 34.57509033572987))
    }
  },
  {
    "id": "default-malindi",
    "predictionPlace": {
      "address": "Malindi, Kenya",
      "bounds": new LatLngBounds(new LatLng(-3.150971710251194, 40.15485756679448), new LatLng(-3.307449598664239, 40.0247382276675))
    }
  },
  {
    id: "default-diani",
    predictionPlace: {
      address: "Diani, Kenya",
      bounds: new LatLngBounds(new LatLng(-4.250129369086789, 39.59884377690327), new LatLng(-4.343102309548563, 39.4686118263552))
    }
  },
  {
    id: "default-watamu",
    predictionPlace: {
      address: "Watamu, Kenya",
      bounds: new LatLngBounds(new LatLng(-3.304279150731548, 40.09906767408871), new LatLng(-3.39013474994421, 39.96963501722905))
    }
  },
  {
    id: "default-machakos",
    predictionPlace: {
      address: "Machakos, Kenya",
      bounds: new LatLngBounds(new LatLng(-1.49131146465332, 37.27601051672266), new LatLng(1.534383361892298, 37.23290796147526))
    }
  },
  {
    id: "default-bungoma",
    predictionPlace: {
      address: "Bungoma, Kenya",
      bounds: new LatLngBounds(new LatLng(0.6053097244771575, 34.57097052722762), new LatLng(0.5419268408623281, 34.53543666996798))
    }
  },
  {
    id: "default-busia",
    predictionPlace: {
      address: "Busia, Kenya",
      bounds: new LatLngBounds(new LatLng(0.4756143182204732, 34.12300699120216), new LatLng(0.4439730011006032, 34.09592184565147))
    }
  },
  {
    id: "default-nyeri",
    predictionPlace: {
      address: "Nyeri, Kenya",
      bounds: new LatLngBounds(new LatLng(-0.4140494429628486, 37.0015096384426), new LatLng(-0.4658601648374509, 36.90389289542625))
    }
  },
  {
    id: "default-meru",
    predictionPlace: {
      address: "Meru, Kenya",
      bounds: new LatLngBounds(new LatLng(0.06591796617162307, 37.66568181664849), new LatLng(0.03081323940865598, 37.62139315345214))
    }
  },
  {
    id: "default-kisii",
    predictionPlace: {
      address: "Kisii, Kenya",
      bounds: new LatLngBounds(new LatLng(-0.6629785708473089, 34.7955036678757), new LatLng(-0.697769745710769, 34.75959197990998))
    }
  },
  {
    id: "default-migori",
    predictionPlace: {
      address: "Migori, Kenya",
      bounds: new LatLngBounds(new LatLng(-1.042785333620574, 34.49389454596687), new LatLng(-1.092300943544612, 34.44600114229182))
    }
  },
  {
    "id": "default-eldoret",
    "predictionPlace": {
      "address": "Eldoret, Uasin Gishu, Kenya",
      "bounds": new LatLngBounds(new LatLng(0.540240, 35.329586), new LatLng(0.423550, 35.218587))
    }
  },
  {
    "id": "default-naivasha",
    "predictionPlace": {
      "address": "Naivasha, Kenya",
      "bounds": new LatLngBounds(new LatLng(-0.707083, 36.489489), new LatLng(-0.819178, 36.358710))
    }
  },
  {
    "id": "default-kitale",
    "predictionPlace": {
      "address": "Kitale, Kenya",
      "bounds": new LatLngBounds(new LatLng(1.022726, 35.023651), new LatLng(0.960682, 34.948684))
    }
  },
  {
    "id": "default-kakamega",
    "predictionPlace": {
      "address": "Kakamega, Kenya",
      "bounds": new LatLngBounds(new LatLng(0.313254, 34.780551), new LatLng(0.270309, 34.713888))
    }
  },
  // {
  //   "id": "default-garissa",
  //   "predictionPlace": {
  //     "address": "Garissa, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.423350, 39.672181), new LatLng(-0.480352, 39.604762))"
  //   }
  // },

  // nairobi areas
  //const placesData = [
  // {
  //   "id": "default-fedha",
  //   "predictionPlace": {
  //     "address": "Fedha, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.324234, 36.878433), new LatLng(-1.320294, 36.884041))"
  //   }
  // },
  // {
  //   "id": "default-donholm",
  //   "predictionPlace": {
  //     "address": "Donholm, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.308979, 36.879389), new LatLng(-1.298977, 36.893605))"
  //   }
  // },
  // {
  //   "id": "default-diamond-plaza",
  //   "predictionPlace": {
  //     "address": "Diamond Plaza, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.259205, 36.826902), new LatLng(-1.258214, 36.828358))"
  //   }
  // },
  // {
  //   "id": "default-park-road",
  //   "predictionPlace": {
  //     "address": "Park Road, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.267576, 36.836609), new LatLng(-1.263449, 36.842036))"
  //   }
  // },
  // {
  //   "id": "default-buruburu",
  //   "predictionPlace": {
  //     "address": "Buruburu, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.297646, 36.874344), new LatLng(-1.286349, 36.887502))"
  //   }
  // },
  // {
  //   "id": "default-savannah",
  //   "predictionPlace": {
  //     "address": "Savannah, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.385038, 36.943732), new LatLng(-1.380353, 36.948827))"
  //   }
  // },
  // {
  //   "id": "default-lavington",
  //   "predictionPlace": {
  //     "address": "Lavington, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.278749, 36.760254), new LatLng(-1.267899, 36.770534))"
  //   }
  // },
  // {
  //   "id": "default-riara",
  //   "predictionPlace": {
  //     "address": "Riara, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.292230, 36.753521), new LatLng(-1.287883, 36.758454))"
  //   }
  // },
  // {
  //   "id": "default-makadara",
  //   "predictionPlace": {
  //     "address": "Makadara, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.310389, 36.875580), new LatLng(-1.303567, 36.881356))"
  //   }
  // },
  // {
  //   "id": "default-madaraka",
  //   "predictionPlace": {
  //     "address": "Madaraka, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.310707, 36.827602), new LatLng(-1.304202, 36.832952))"
  //   }
  // },
  // {
  //   "id": "default-chiromo",
  //   "predictionPlace": {
  //     "address": "Chiromo, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.268095, 36.800077), new LatLng(-1.260812, 36.808821))"
  //   }
  // },
  // {
  //   "id": "default-loresho",
  //   "predictionPlace": {
  //     "address": "Loresho, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.252237, 36.748901), new LatLng(-1.244525, 36.757360))"
  //   }
  // },
  // {
  //   "id": "default-westlands",
  //   "predictionPlace": {
  //     "address": "Westlands, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.276352, 36.797298), new LatLng(-1.263017, 36.809279))"
  //   }
  // },
  // {
  //   "id": "default-rosslyn",
  //   "predictionPlace": {
  //     "address": "ROSSLYN, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.239323, 36.810676), new LatLng(-1.233200, 36.817186))"
  //   }
  // },
  // {
  //   "id": "default-kahawa",
  //   "predictionPlace": {
  //     "address": "Kahawa, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.175936, 36.908885), new LatLng(-1.162146, 36.920745))"
  //   }
  // },
  // {
  //   "id": "default-south-b",
  //   "predictionPlace": {
  //     "address": "South B, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.317396, 36.834093), new LatLng(-1.310646, 36.841150))"
  //   }
  // },
  // {
  //   "id": "default-rongai",
  //   "predictionPlace": {
  //     "address": "Rongai, Nairobi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-1.392789, 36.870934), new LatLng(-1.384719, 36.879289))"
  //   }
  // },
  // //const nakuruPlacesData = [
  // {
  //   "id": "default-nakuru",
  //   "predictionPlace": {
  //     "address": "Nakuru, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.343072, 36.055726), new LatLng(-0.279199, 36.110987))"
  //   }
  // },
  // {
  //   "id": "default-milimani",
  //   "predictionPlace": {
  //     "address": "Milimani, Nakuru, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.290305, 36.071625), new LatLng(-0.283722, 36.078490))"
  //   }
  // },
  // {
  //   "id": "default-kaimunyi",
  //   "predictionPlace": {
  //     "address": "Kaimunyi, Nakuru, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.334533, 36.091672), new LatLng(-0.328050, 36.096895))"
  //   }
  // },
  // {
  //   "id": "default-free-area",
  //   "predictionPlace": {
  //     "address": "FREE AREA, Nakuru, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.312938, 36.083053), new LatLng(-0.306774, 36.088609))"
  //   }
  // },
  // {
  //   "id": "default-naka",
  //   "predictionPlace": {
  //     "address": "Naka, Nakuru, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.302792, 36.074391), new LatLng(-0.298033, 36.078855))"
  //   }
  // },
  // {
  //   "id": "default-pipeline",
  //   "predictionPlace": {
  //     "address": "Pipeline, Nakuru, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.316700, 36.088950), new LatLng(-0.312000, 36.093655))"
  //   }
  // },
  // {
  //   "id": "default-teachers",
  //   "predictionPlace": {
  //     "address": "Teachers, Nakuru, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.299740, 36.070541), new LatLng(-0.294990, 36.075302))"
  //   }
  // },
  // {
  //   "id": "default-satellite",
  //   "predictionPlace": {
  //     "address": "Satellite, Nakuru, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.324509, 36.082095), new LatLng(-0.319764, 36.086842))"
  //   }
  // },
  // {
  //   "id": "default-barnabas",
  //   "predictionPlace": {
  //     "address": "Barnabas, Nakuru, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.340154, 36.095319), new LatLng(-0.335407, 36.100066))"
  //   }
  // },
  // {
  //   "id": "default-blankets",
  //   "predictionPlace": {
  //     "address": "Blankets, Nakuru, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.328831, 36.065734), new LatLng(-0.324084, 36.070482))"
  //   }
  // },
  // {
  //   "id": "default-bangladesh",
  //   "predictionPlace": {
  //     "address": "Bangladesh, Nakuru, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.325618, 36.054301), new LatLng(-0.320870, 36.059049))"
  //   }
  // },
  // {
  //   "id": "default-racecourse",
  //   "predictionPlace": {
  //     "address": "Racecourse, Nakuru, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.303802, 36.080165), new LatLng(-0.299053, 36.084913))"
  //   }
  // },
  // {
  //   "id": "default-ngala",
  //   "predictionPlace": {
  //     "address": "NGALA, Nakuru, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.292341, 36.062128), new LatLng(-0.287592, 36.066876))"
  //   }
  // },
  // {
  //   "id": "default-london",
  //   "predictionPlace": {
  //     "address": "London, Nakuru, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.303987, 36.070917), new LatLng(-0.299238, 36.075665))"
  //   }
  // }
  // ,

  // //const kisumuPlacesData = [
  // {
  //   "id": "default-kisumu",
  //   "predictionPlace": {
  //     "address": "Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.112139, 34.644126), new LatLng(-0.056797, 34.797447))"
  //   }
  // },
  // {
  //   "id": "default-milimani",
  //   "predictionPlace": {
  //     "address": "Milimani, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.092264, 34.748105), new LatLng(-0.086213, 34.753240))"
  //   }
  // },
  // {
  //   "id": "default-lolwe",
  //   "predictionPlace": {
  //     "address": "Lolwe, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.103874, 34.751935), new LatLng(-0.099412, 34.755632))"
  //   }
  // },
  // {
  //   "id": "default-migosi",
  //   "predictionPlace": {
  //     "address": "Migosi, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.092981, 34.768314), new LatLng(-0.088601, 34.772105))"
  //   }
  // },
  // {
  //   "id": "default-car-wash",
  //   "predictionPlace": {
  //     "address": "Car Wash, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.100445, 34.767202), new LatLng(-0.095750, 34.771208))"
  //   }
  // },
  // {
  //   "id": "default-nyalenda",
  //   "predictionPlace": {
  //     "address": "Nyalenda, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.106082, 34.731042), new LatLng(-0.100306, 34.736234))"
  //   }
  // },
  // {
  //   "id": "default-tom-mboya",
  //   "predictionPlace": {
  //     "address": "Tom Mboya, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.102558, 34.743890), new LatLng(-0.097712, 34.748263))"
  //   }
  // },
  // {
  //   "id": "default-kenyare",
  //   "predictionPlace": {
  //     "address": "KenyaRe, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.089962, 34.752376), new LatLng(-0.085091, 34.756534))"
  //   }
  // },
  // {
  //   "id": "default-polyview",
  //   "predictionPlace": {
  //     "address": "Polyview, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.091245, 34.762309), new LatLng(-0.086535, 34.766408))"
  //   }
  // },
  // {
  //   "id": "default-manyatta",
  //   "predictionPlace": {
  //     "address": "Manyatta, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.107385, 34.769202), new LatLng(-0.102421, 34.773759))"
  //   }
  // },
  // {
  //   "id": "default-riat",
  //   "predictionPlace": {
  //     "address": "Riat, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.082173, 34.735818), new LatLng(-0.077573, 34.739927))"
  //   }
  // },
  // {
  //   "id": "default-nyakhera",
  //   "predictionPlace": {
  //     "address": "Nyakhera, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.096612, 34.760159), new LatLng(-0.092104, 34.764095))"
  //   }
  // },
  // {
  //   "id": "default-kanyakwari",
  //   "predictionPlace": {
  //     "address": "Kanyakwari, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.089824, 34.775220), new LatLng(-0.085312, 34.779144))"
  //   }
  // },
  // {
  //   "id": "default-mamboleo",
  //   "predictionPlace": {
  //     "address": "Mamboleo, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.081891, 34.784239), new LatLng(-0.077371, 34.788163))"
  //   }
  // },
  // {
  //   "id": "default-mountain-view",
  //   "predictionPlace": {
  //     "address": "Mountain View, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.105812, 34.746281), new LatLng(-0.101288, 34.750205))"
  //   }
  // },
  // {
  //   "id": "default-ekweli",
  //   "predictionPlace": {
  //     "address": "Ekweli, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.109972, 34.761191), new LatLng(-0.105448, 34.765115))"
  //   }
  // },
  // {
  //   "id": "default-highrise",
  //   "predictionPlace": {
  //     "address": "Highrise, Kisumu, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-0.097501, 34.749232), new LatLng(-0.092987, 34.753157))"
  //   }
  // },
  // // ];

  // //const mombasaPlacesData = [
  // {
  //   "id": "default-mombasa",
  //   "predictionPlace": {
  //     "address": "Mombasa, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-4.102823, 39.635027), new LatLng(-3.946047, 39.811853))"
  //   }
  // },
  // {
  //   "id": "default-nyali",
  //   "predictionPlace": {
  //     "address": "Nyali, Mombasa, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-4.059832, 39.709132), new LatLng(-4.027701, 39.735146))"
  //   }
  // },
  // {
  //   "id": "default-kizingo",
  //   "predictionPlace": {
  //     "address": "Kizingo, Mombasa, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-4.065329, 39.669912), new LatLng(-4.055829, 39.679532))"
  //   }
  // },
  // {
  //   "id": "default-kiambeni",
  //   "predictionPlace": {
  //     "address": "Kiambeni, Mombasa, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-4.017682, 39.607364), new LatLng(-4.006759, 39.619271))"
  //   }
  // },
  // {
  //   "id": "default-mtwapa",
  //   "predictionPlace": {
  //     "address": "Mtwapa, Mombasa, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-3.945327, 39.733564), new LatLng(-3.921835, 39.754206))"
  //   }
  // },
  // {
  //   "id": "default-mshomoroni",
  //   "predictionPlace": {
  //     "address": "Mshomoroni, Mombasa, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-4.024719, 39.683943), new LatLng(-4.013517, 39.697243))"
  //   }
  // },
  // {
  //   "id": "default-migadini",
  //   "predictionPlace": {
  //     "address": "Migadini, Mombasa, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-4.014029, 39.650263), new LatLng(-4.004579, 39.659163))"
  //   }
  // },
  // {
  //   "id": "default-msambweni",
  //   "predictionPlace": {
  //     "address": "Msambweni, Mombasa, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-4.087591, 39.489057), new LatLng(-4.078216, 39.498951))"
  //   }
  // },
  // {
  //   "id": "default-makamba",
  //   "predictionPlace": {
  //     "address": "Makamba, Mombasa, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-4.031901, 39.647738), new LatLng(-4.021401, 39.656481))"
  //   }
  // },

  // //const malindiPlacesData = [
  // {
  //   "id": "default-malindi",
  //   "predictionPlace": {
  //     "address": "Malindi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-3.233226, 40.091415), new LatLng(-3.196512, 40.121786))"
  //   }
  // },
  // {
  //   "id": "default-kibokoni",
  //   "predictionPlace": {
  //     "address": "Kibokoni, Malindi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-3.224068, 40.112377), new LatLng(-3.217935, 40.117907))"
  //   }
  // },
  // {
  //   "id": "default-majengo",
  //   "predictionPlace": {
  //     "address": "Majengo, Malindi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-3.225921, 40.117325), new LatLng(-3.219781, 40.122858))"
  //   }
  // },
  // {
  //   "id": "default-kiraho",
  //   "predictionPlace": {
  //     "address": "Kiraho, Malindi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-3.216769, 40.104236), new LatLng(-3.210643, 40.109763))"
  //   }
  // },
  // {
  //   "id": "default-kosovo",
  //   "predictionPlace": {
  //     "address": "Kosovo, Malindi, Kenya",
  //     "bounds": "new LatLngBounds(new LatLng(-3.223176, 40.100938), new LatLng(-3.217050, 40.106466))"
  //   }
  // }






  // {
  //   id: 'default-helsinki',
  //   predictionPlace: {
  //     address: 'Helsinki, Finland',
  //     bounds: new LatLngBounds(new LatLng(60.29783, 25.25448), new LatLng(59.92248, 24.78287)),
  //   },
  // },
  // {
  //   id: 'default-turku',
  //   predictionPlace: {
  //     address: 'Turku, Finland',
  //     bounds: new LatLngBounds(new LatLng(60.53045, 22.38197), new LatLng(60.33361, 22.06644)),
  //   },
  // },
  // {
  //   id: 'default-tampere',
  //   predictionPlace: {
  //     address: 'Tampere, Finland',
  //     bounds: new LatLngBounds(new LatLng(61.83657, 24.11838), new LatLng(61.42728, 23.5422)),
  //   },
  // },
  // {
  //   id: 'default-oulu',
  //   predictionPlace: {
  //     address: 'Oulu, Finland',
  //     bounds: new LatLngBounds(new LatLng(65.56434, 26.77069), new LatLng(64.8443, 24.11494)),
  //   },
  // },
  // {
  //   id: 'default-ruka',
  //   predictionPlace: {
  //     address: 'Ruka, Finland',
  //     bounds: new LatLngBounds(new LatLng(66.16997, 29.16773), new LatLng(66.16095, 29.13572)),
  //   },
  // },
];
export default defaultLocations;
